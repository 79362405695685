import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import {
  loadStripe,
  Stripe,
  StripeCardNumberElement,
  StripeElements,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { FormEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { CardNumberElement } from "@stripe/react-stripe-js";

export const configJSON = require("./config");

export interface Props {
  onCloseDialog: () => void;
  onSuccess: () => void;
  onCancelSubscription: (isImmediate: boolean) => void;
}

interface ICardItem {
  payment_id: string;
  card_number: string;
  card_type: string;
  default: boolean;
}

export interface S {
  isLoading: boolean;
  cardListItems: ICardItem[];
  isOpenCardListView: boolean;
  selectedCard: string;
  errorMsg: string;
  stripeAction: string;
}

export interface SS {}

export default class EditStripeSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCardListApiId: string = "";
  deleteCardApiId: string = "";
  addNewCardApiId: string = "";
  updateActiveCardApiId: string = "";
  cancelSubscriptionApiId: string = "";
  cancelSubscriptionAndLogoutApiId: string = "";
  logoutApiId: string = "";
  stripKey: string = configJSON.stripePublishableKey;
  stripePromise = loadStripe(this.stripKey);
  stripePaymentObject: Stripe | null = null;

  options: StripeElementsOptions = {
    mode: configJSON.optionMode,
    amount: configJSON.optionAmount,
    currency: configJSON.optionCurrency,
    paymentMethodCreation: configJSON.optionPaymentMethod,
    appearance: {},
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: false,
      cardListItems: [],
      isOpenCardListView: true,
      selectedCard: "",
      errorMsg: "",
      stripeAction: "edit",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getAllCardList();
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getCardListApiId) {
      this.toggleLoader(false);
      if (responseJson && responseJson.attached_cards) {
        const activeCard = responseJson.attached_cards.find(
          (cardItem: ICardItem) => cardItem.default
        );

        this.setState({
          cardListItems: responseJson.attached_cards,
          selectedCard: activeCard?.payment_id || "",
        });
      }
    }

    if (apiRequestCallId === this.updateActiveCardApiId) {
      this.setUpdateDefaultCardResponse(responseJson);
    }

    if (apiRequestCallId === this.deleteCardApiId) {
      this.setRemoveCardResponse(responseJson);
    }

    if (apiRequestCallId === this.addNewCardApiId) {
      this.setAddNewCardResponse(responseJson);
    }

    if (apiRequestCallId === this.cancelSubscriptionApiId) {
      this.setCancelSubscriptionResponse(responseJson);
    }
  }

  setCancelSubscriptionResponse = (responseJson: { message?: string }) => {
    this.toggleLoader(false);
    if (
      responseJson &&
      responseJson?.message === configJSON.cancelAtMidMonthSuccessMsg
    ) {
      this.props.onCancelSubscription(false);
    }
  };

  setUpdateDefaultCardResponse = (responseJson: { message?: string }) => {
    this.toggleLoader(false);
    if (
      responseJson &&
      responseJson?.message === configJSON.updateCardSuccessMsg
    ) {
      this.props.onSuccess();
    }
  };

  setRemoveCardResponse = (responseJson: { message?: string }) => {
    this.toggleLoader(false);
    if (
      responseJson &&
      responseJson?.message === configJSON.deleteCardSuccessMsg
    ) {
      this.getAllCardList();
    }
  };

  setAddNewCardResponse = async (responseJson: {
    message?: string;
    error?: string;
    payment_method_id?: string;
    client_secret?: string;
  }) => {
    if (
      responseJson &&
      responseJson?.message === configJSON.addCardSuccessMsg
    ) {
      this.getAllCardList();
      this.toggleShowCardView();
    } else if (
      responseJson?.message === configJSON.authenticationRequiredMsg &&
      this.stripePaymentObject
    ) {
      const { setupIntent } = await this.stripePaymentObject.confirmSetup({
        clientSecret: responseJson.client_secret as string,
        redirect: "if_required",
      });
      if (setupIntent?.status === "succeeded") {
        this.getAllCardList();
        this.toggleShowCardView();
      } else {
        this.setErrorMessages(configJSON.addCardFailedMsg);
      }
    } else {
      let errorMsg = responseJson?.message || responseJson?.error;
      this.setErrorMessages(errorMsg as string);
    }
  };

  setErrorMessages = (errorMsg: string) => {
    this.toggleLoader(false);
    this.setState({ errorMsg: errorMsg }, () => {
      setTimeout(() => {
        this.setState({ errorMsg: "" });
      }, 3000);
    });
  };

  onCloseStripeGateway = () => {
    this.props.onCloseDialog();
  };

  toggleLoader = (isLoading: boolean) => {
    this.setState({ isLoading: isLoading });
  };

  toggleShowCardView = () => {
    this.setState({ isOpenCardListView: !this.state.isOpenCardListView });
  };

  handleChangeDefaultCard = (
    event: React.ChangeEvent<HTMLInputElement>,
    cardId: string
  ) => {
    this.setState({ selectedCard: cardId });
  };

  handleChangeStripeAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ stripeAction: (event.target as HTMLInputElement).value });
  };

  handleSubmit = async (
    event: FormEvent,
    stripe: Stripe | null,
    elements: StripeElements | null
  ) => {
    this.stripePaymentObject = stripe;
    this.toggleLoader(true);
    event.preventDefault();

    if (!stripe || !elements) {
      this.toggleLoader(false);
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      this.toggleLoader(false);
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { error, token } = await stripe.createToken(
      cardElement as StripeCardNumberElement
    );
    if (error && error.message) {
      this.setState({ errorMsg: error.message, isLoading: false }, () => {
        setTimeout(() => {
          this.setState({ errorMsg: "" });
        }, 3000);
      });
    }
    if (token && token.id) {
      this.addNewCardForPayment(token.id);
    }
  };

  getAllCardList = async () => {
    this.toggleLoader(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const getAllCardsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCardListApiId = getAllCardsRequestMsg.messageId;

    getAllCardsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showCardListApiEndPoint
    );

    getAllCardsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllCardsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(getAllCardsRequestMsg.id, getAllCardsRequestMsg);
  };

  updateDefaultCardForPayment = async () => {
    this.toggleLoader(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const updateDefaultCardRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateActiveCardApiId = updateDefaultCardRequestMsg.messageId;

    updateDefaultCardRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setDefaultCardApiEndPoint
    );

    updateDefaultCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateDefaultCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.confirmPaymentMethod
    );
    updateDefaultCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        card_token: this.state.selectedCard,
      })
    );

    runEngine.sendMessage(
      updateDefaultCardRequestMsg.id,
      updateDefaultCardRequestMsg
    );
  };

  addNewCardForPayment = async (cardToken: string) => {
    this.toggleLoader(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const addNewCardRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addNewCardApiId = addNewCardRequestMsg.messageId;

    addNewCardRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addNewCardApiEndPoint
    );

    addNewCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addNewCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.confirmPaymentMethod
    );
    addNewCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        card_token: cardToken,
      })
    );

    runEngine.sendMessage(addNewCardRequestMsg.id, addNewCardRequestMsg);
  };

  deleteCard = async (cardToken: string) => {
    this.toggleLoader(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const deleteCardRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCardApiId = deleteCardRequestMsg.messageId;

    deleteCardRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCardApiEndPoint + cardToken
    );

    deleteCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethod
    );

    runEngine.sendMessage(deleteCardRequestMsg.id, deleteCardRequestMsg);
  };

  cancelSubscriptionAtMidMonth = async () => {
    this.toggleLoader(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const revokeSubscriptionRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelSubscriptionApiId = revokeSubscriptionRequestMsg.messageId;

    revokeSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelSubscriptionMidMonthApiEndPoint
    );

    revokeSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    revokeSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethod
    );

    runEngine.sendMessage(
      revokeSubscriptionRequestMsg.id,
      revokeSubscriptionRequestMsg
    );
  };
}
